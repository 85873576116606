import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import Mint from "./pages/Mint";

export default function Router() {
  return (
    <Routes>
      <Route path="mint" element={<Mint />} />
      <Route path="" element={<Mint />} />
      <Route path="*" element={<Home />} />
    </Routes>
  );
}

export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, [pathname]);
  return <></>;
}
