import twitter from "../assets/icons/twitter.svg";
import discord from "../assets/icons/discord.svg";
import alliance_discord from "../assets/icons/alliance_discord.png";
import instagram from "../assets/icons/instagram.svg";

export default function Footer() {
  return (
    <footer className="p-3 gap-2 d-flex flex-column align-items-end">
      <div>
        <a
          className="m-2"
          href="https://twitter.com/tribute__nft"
          target={"_blank"}
          rel="noreferrer"
        >
          <img style={{ height: "30px" }} src={twitter} alt="twitter tri3ute" />
        </a>
        <a
          className="m-2"
          target={"_blank"}
          rel="noreferrer"
          href="https://instagram.com/tribute__nft"
        >
          <img
            style={{ height: "30px" }}
            src={instagram}
            alt="instagram tri3ute"
          />
        </a>
      </div>
      <div>
        <a className="m-2" href="">
          <img src={alliance_discord} alt="discord tri3ute" />
        </a>
      </div>
    </footer>
  );
}
