export const environment = {
  chainId: "ethereum",
  contract: "0x4623EC48F6f89076289cDcd60a65A88bf93Bbe6f",
  vipWhitelist: [
    "0x0a6ebecbb2e364bf4b9c490a58c18aed31e4d5eb",
    "0xf2b1f9511db8747daa82df0f081651b249cdbf3d",
    "0xdc888105b6bcee4b38a938046bbc46e936832b7f",
    "0x53965cd7992bc61b4807aa7ace3bec0062c90de2",
    "0x5d30dc36255ae4ad6022c7b8ba640e709993c4d4",
    "0xbbd3fdd1dac81a1b95cfe0568a0de0e7129cb472",
    "0x3fb80007367f16ade9168a3be2fcca5c7f357cbc",
    "0x4c5fae2d9e2283285707ac13dca0ed6740a18a42",
    "0x97a479abb46a9ff92b2bf26d8c110c89c83916fe",
    "0xce5c80f38f01e4a29d9bd1f64bb7905b3da6c9d0",
    "0xab7eb929f1a07507d4df1f11f0c9513f86fbd3a0",
    "0x0d7ef7d92ee56d3a3d12b41985363ae0aacca3a5",
    "0x0dfb9b5bed381d212701cb92ecec4e4b78a10259",
    "0x10985dc6335815a8e5d3eed2dad3946da75b777a",
    "0x191a310f3e935e68e2faef6ef148e53dcc16ee27",
    "0x5de2e424d1c3345a01d13d3c41b6324b96aab1dd",
    "0x6b3bd76932f235ea6abf880c060ee86467544e90",
    "0x74e163959d171d49a042058de03cb9608d5cc94f",
    "0x9b2f12393217b6a0d1fa10ceb7aa49858edeffe5",
    "0x9ff20369e90703d97da12c50d58005140859f207",
    "0xab513c359f77df528361cc06469c581e368d1a9f",
    "0xbb5bbdd1d9196132125a314601e2b6f09663f7b7",
    "0xd670634a32c9e73ac318df2527fd4dfbc4db95d5",
    "0xd9af96861de6992b299e9ac004aa4c68771d0815",
    "0x26770adc0903ba5958fb3a039956f5b1d57cc214",
    "0xd9c609efc4a40a3356bb80e1ac0911df350ead72",
    "0x6634fcc91d5c5952b0ff339d87f01ff7a127bfe7",
    "0x73b611369ba35bdbce2ec1dd0e53743bab8ec2f9",
    "0xfc080b19617a8139619c762d2c97b39a4e5b50cf",
    "0x00f7a5815ecb00b4d9bf71719806fbe7775c9b02",
    "0x046a588b1dad129aab8b88f7359d7ebb35c985ee",
    "0x0bd1c5591ee2848df80c48a774ad85f3300ab4dd",
    "0x0c6f0e3250b334ca867c02f0aa4cde95f02471d1",
    "0x0d65f6e01b73e844e631c74396519f20dcff2617",
    "0x0e68bc613f8e6d48e586ff2ea42d35f55fb3edab",
    "0x0f0c6ead741f9f4a217503f96239b5e4b84d80cf",
    "0x10c22a6c7b8384f95521d3714b3c3a49a1f6275b",
    "0x1243ee157d3bdb5374a0d3a51c177ab1edecb735",
    "0x1628e6d7c871a2eca89693e6fc49b3b7cfdb1964",
    "0x194364a8e9dd50f89a1073e51361985f9a1fe573",
    "0x20239f96232df5be612228806dd78e845abcc083",
    "0x207c25130bd463973e76084a048174d2406be37e",
    "0x2080bc03751d8623978db005e93d189770c7ebf7",
    "0x275c681efa0ad4b3fefafb40fba467cbac00c751",
    "0x2c03d3f41eff1581f0b4532b79fb3b2653487e66",
    "0x2f265e93ac0d738152c69b770e98261bed5cebaa",
    "0x3318b8a2986676de36e1f2d1b9fc498b2df0bd13",
    "0x3433283582a46878b193e6a570df195926307648",
    "0x38d69623005726484222e3fa20ca8a445b5924ee",
    "0x3e4233921efef6dcbbfc63821f0923382ccebbbc",
    "0x419a74e1fc7400957239fbbf1a4963a5d2d263a1",
    "0x447742df06d1b2167599bc03ac9d7062bc9ba3d9",
    "0x4cfd50f62df880cccd5e6d489e9ea3039819aad1",
    "0x5043144f14a4c726461d38ea862959c72700bc27",
    "0x51597c5ef4b7643245f735558e7f67ceb50a6a7f",
    "0x54f85ec7073ed8c12b3b38a78cb51479d4e0c0ea",
    "0x55806f2b324d509befe4735ed7bf3c511e234105",
    "0x57fdfa0c6c5f63c60edf7ed38e9cf5385233c8d2",
    "0x5938740715149cbc72493674388a190ba2ae0d5b",
    "0x6013a352ab61f42cad387456591295b9d7a0cd3d",
    "0x64bd40351d309e2c705a270b8f0697be4f8fcabe",
    "0x6866aa671ed6ec2fa53f3a1b381763f91155d81d",
    "0x6fcb576b8eaf472221f3154f6a3eaafed2fa27d5",
    "0x71564f7c953b8db37ae20439e89207e92492e1c4",
    "0x76f143a7ed6f8959e181c1dffedf2f90e6c4f2d4",
    "0x77f27401f0c2c169422ab12ce3a0520d7ba3c3f8",
    "0x7b9f807f23686e8d5f9fd951fef545576932fe4a",
    "0x9709c643916d46fc2ca0c1678c59e14a0c539877",
    "0x98e35ed59bc63fcf01d48bd7620e8dce34e27e7e",
    "0xab268c14e235e65c1c2f4abc673b3b03ae6fbf9e",
    "0xb09be4c53cd5fe605b4d8fe819191fed8ab59401",
    "0xb230eeec2b94dbf0215f27c082e315e167ea7356",
    "0xb47832ca65e661b2b54de39f24775c1d82c216f9",
    "0xb501404ad37b8b668740944b25ee0e221a737d59",
    "0xb5768a78cc06617f324bfb77512d318791adf238",
    "0xb9fbb41ca2fbd521e7d5f1499db0a2dae5e0df33",
    "0xc1263a10b5bf7670ad4410bca84a865cf7b05054",
    "0xccd0c747562ce73f528e3d91d75de02608afae7e",
    "0xcdaa484a6a787cd20707cfa94d9841850cdf99ff",
    "0xcfd43f457631bc187d288ba35900394c04a01002",
    "0xd440614fe77378814d37c23bdb13784f81aea792",
    "0xd47e750f4bdef38591509464fbd3cb4bed512f21",
    "0xd798faae413f4c11bd30796ebfce4bf30020c608",
    "0xd812d27bc75bfae99eda9e29ad45baaf986d94d2",
    "0xd89b25111276db7e359926bcfe228071caafd467",
    "0xd8ce6d8e53bdf167e810202d02613ff474c1968d",
    "0xd99bd5cef9681b5cbf78b02827a69998704b5f80",
    "0xdb303931522e851504a23349372423575c0b74a6",
    "0xdd2c91f276a65e2a2b348a22c9f9c0f117672ce6",
    "0xddf403807c103624d3ccee729a88103f3fb55cb2",
    "0xe5b5e9d09c4f02376503eff11e83d2792ef43b1b",
    "0xe825028a162c87df9b0eea68e2217f30015e999b",
    "0xe90ae13bf7df67501b18a521dd7a6de14e0769cf",
    "0xf0b32df3d07b78ba1f1bb79d81896fbdd7e56567",
    "0xf22589c95afb388a46bc2011fac97f683da1e8f4",
    "0xf5e54dc5cb51b995c9450567cf00ffa367957f94",
    "0xf6c2997fff7affe4a7601988539089ce3af9daae",
    "0xfc9cb292f0d10978a894a4daadf726f4c853cb72",
    "0xfca305ac728e4e2823fd30517af342e77975489b",
    "0x0045770bba2805e1631631e7ddc917ba9d4fc523",
    "0x170ff879349558bb4b1123525e7921ac053a6e78",
    "0x1d3d9e73f7eed8455ca2c58b2152a1975b4ddb11",
    "0x26c46e6f7b37e3eea85b6edf0e95583d0bb292ad",
    "0x326faa0c3e9d6ad0c6f8978e2aa1d659bfa342e6",
    "0x3bb93b2a75de271c54b64555356eda4a4f225d56",
    "0x3d757454ea8ebbf056b618a048c153eddb95b038",
    "0x54b1c3fc169b994b1409a31926e232d2ff18a5a1",
    "0x7e1f62fa971493d74ef655cb7f19e004211993c7",
    "0x806a1226166ddb39551b59ed952c56b6373a9d90",
    "0x881134ca7693530417c06a57c23e99c9e8f0ef7a",
    "0x89c50d41bef923cc949051e1358d67bf13cad976",
    "0x8e0d9d97efc9d2a32f18ca12e2f177eec382d951",
    "0x98268948278e168d57e3409e42b9dfa97f0d7aed",
    "0x9a344cf0de3d7311bf6aec94c46eb1fa35e55468",
    "0xa21bc5457d57ec66e7657d299225a49b87c4afcb",
    "0xaba93498a69373b5e5f72254a513bfaf77253d16",
    "0xad41df7047de1f4efa00b4cd7c4065d83dd76d8c",
    "0xb09090cc54b5341c89d46c5202309463986ab44e",
    "0xb1093572c8d1c1e8a7a384b84d398905072d8fc0",
    "0xbe3a795a25998b2aa882b6c8fc1ce894535b87e1",
    "0xda3863583c9fcd8a5d3506d91aaf33157124d1d1",
    "0xe737b4fd6c96a38ee233d0857a6a3218fd40eeb2",
    "0xe9d99c29b2872784b7d28f10ed37347374fb084b",
    "0xfaeab24340ef750f67ee158deaafc82d34f019bd",
    "0x0fb5ac3b10bcf21bcd701dea5f1dd01312b3c7cf",
    "0x13bdef348c679fba043b96195536a404cdd8e66f",
    "0x27ed85e6547456d6a4aabdb8ce63efbff7ce758f",
    "0x3c011fd929500b9e504d2cb50f265595a8e7cc5d",
    "0x489405cac5ef218952a5341a709b7c7b9e332aaf",
    "0xa0a5d0fd1efaa323ffb46e04b23660126b8cf2b2",
    "0xb735da2315e2fe33ee7d43d2bdddb9aac492abb0",
    "0xf3408d044e274fcea5151f30db1fd47ccda084d1",
    "0xfa0fff276d56effc74e663616f3b392de68f419a",
    "0x2307f7383edf71da8c212076c03f034dc16f107b",
    "0x24bb3fbba92b9697487b9ce7e216c03a7e6a38ce",
    "0x4fdc1e3a6c0243a089d80e90d7bd0e060044e267",
    "0x5424c4c60ad3f7128179912f065589912d3c6c11",
    "0x628e8030fc72c7ae9bd1a009acee04e690901023",
    "0x90611f1a9b6f964801408a4a1df2df783a58f65d",
    "0xaa8a771cc7ff3d549a6e2019a2124cc9f146490b",
    "0xc5cddf9c3d788ff4751c1e31f946c2536b34fd73",
    "0xc88fbcdebf8a2f068fba8edc837ad9d033c4028a",
    "0xd12b21c74c9a831ba7b4e5e93d83ca1eb5ff3d40",
    "0x0255e0748dae97695acd1506b7efa18cb7a38ce9",
    "0x03299cc26ba3c3ee6696b718695e0b46fbac5daf",
    "0x03dab1fb37bb30f38492f1f485ce3323da7d3005",
    "0x03e44389b831e3d3ef9fb58dcc2eb572d5b6dec6",
    "0x05153ba6fc6aa8853d60bd76b20542010c2e2fe1",
    "0x05265c4695e895177365d9adcc291ed8ee6cffbe",
    "0x059858cfdb4b8d47e632990e65c90cd5ff35ea1f",
    "0x05c719f5b98c203f2b984b3e71756309dd351e0f",
    "0x08f32f50ede62b62371ba485b6c15c6318269592",
    "0x098f8cb47b8b74a0173ba169276529931d135b99",
    "0x0a9173154c1d2f9f840f0eebc8d42165cbbe2a6a",
    "0x0c3ce78970ebb2b8a8e8fcd99e0e5fe4d2a9d56f",
    "0x0c99be639e45ea6f25c39812daebdfca0cbd0724",
    "0x0ce3695c8a61444d854191969b692d269a9dca66",
    "0x0dcb7f3a7a8c42b431a07babbe02b011c69c88f6",
    "0x0e65292681aec63dd14673574b90d64ef00d5349",
    "0x125f70c74ba7dbb8e4ac27f66bcc2a040750c4ce",
    "0x1298b2688bba1b07c9b870bb6ee5a1896c8cc4cc",
    "0x142875238256444be2243b01cbe613b0fac3f64e",
    "0x1563e305c54e8fa47f987d329103924c129c23a4",
    "0x15cd2364d6684b0d0610b6be748d90ae0bf48d6f",
    "0x15f0354f187b7ff6d8c5685751636bec048945f1",
    "0x180c06de66e2d5bc1bc13ad17634b992f76ea90c",
    "0x180f79b2809c3d341a5b7a06fb059b388433fa2a",
    "0x19feaf32733e55a20efa82c2b7b01b8e4d3c6f4d",
    "0x1c6c1a0ce630b9bdac5f3bbc25ec4f71a0355486",
    "0x1cf922da32d57196cc898e679c82f02aeee0de9b",
    "0x1d9c72a173a3451352b1ac0c2a3473f11e70165a",
    "0x1da372e45508eb142e96fb9e96aee5a0b90003b7",
    "0x1df87ab124cc0d52ec2faf3dcffcdb9f776149a1",
    "0x1e7038e466ce9b3e37593cf90af4882e3ef0bf41",
    "0x1e7b01eaace123e440bdd98fa9b58b0cb458c267",
    "0x1e7bdd10923dacce624076c906275af5fcc7b082",
    "0x1e7cd0307a3444c67330ffed68e058b78c4ed583",
    "0x1ed1dffbca074291de2a2539c92b6e878b7c83da",
    "0x1f3b00363a447c88a255ea329c3e8e96c8872196",
    "0x1f3d7f63bc850c86cf7d1dd6aedb0ee7f1096e41",
    "0x23a6a657ca8f2e60f723152a598561354b5516f6",
    "0x254f18b3d2bfeae6931c2432c6dd34fca16cb954",
    "0x25d0783b35395c078d0789464f59f556fac51f64",
    "0x28f07a28afb69aa5b81540f49dd3676c11c4ab36",
    "0x29cf4c62fbb6bd5cdbd0fab6fb301e5196693861",
    "0x2c432ce99c839f91f9be61a29c977077ccde00f6",
    "0x2f453e34c74519e37177fe4e533ad2fe514e0a0d",
    "0x304a5a76ff19f7de94ba235241c2418b9d856e47",
    "0x30a37889e50d288d8c0d9019162e8ca207d110f0",
    "0x334bdd31fd73f87669cb2b347cbcc0d1269623b4",
    "0x34c9737cfb8b2f927bee60de177015e2f154a75c",
    "0x3531bbafa118df128e797f5aa37035a1d1795515",
    "0x360cb7ebd85fd8adad909be393f9cdcc378b5cf6",
    "0x363fbd6f28d4d092771f2897ab261ee1532298b7",
    "0x36e18ab9da425d5b59de9176f19d867f8eb30b25",
    "0x36f81adc7e6fdfcc30ef8bc24b4f78e8a67cc95d",
    "0x37421a237816b57b360fc0e6036cfc46c4c37dfe",
    "0x391018a054c9c55d534a153be308711ac4b6c116",
    "0x3b287ddb497690a05aefc9277531d42c38d53b9b",
    "0x3d434fcdf8f950c751eb890e6359303f85beca2a",
    "0x3fb47f7db5ab1a3af5ac3417a77cb7dbe70ee6c5",
    "0x439cd9007b797fbe6999e18e609b87d03191621c",
    "0x46b2cf252c96190f33f56bf77054b8b433d77603",
    "0x4af4ae3e68fae38f163291d241d9759960699505",
    "0x4ea1eab5dcdde01dbba9a96d50f7d78385558885",
    "0x4ea80f8f901f97531f81c8eb62d6825ec22f265e",
    "0x4eb859fc83977267fbb6ae1066a51fb4c9086c28",
    "0x5177277290862f9747d02ee571dc5467d163b9a4",
    "0x525aadb22d87caa26b00587dc6bf9a6cc2f414e5",
    "0x53cf6198b88c405870d88d75968b34ce53a6c968",
    "0x53dffa05086961935528a6094b5d8f6ee02b7284",
    "0x557400851eaef5ffc8017f5f30842a0631d76379",
    "0x5755ab3d8b03af033d47e164f1cd2824e2d475dd",
    "0x5788858ed0ad70dea5056738b7ffd1810eacb84f",
    "0x57ed518f172955b098f8c19c019d7b0fc244e62c",
    "0x59288ff29b495b77af052442d32392bc50995e49",
    "0x5a8bd25e00a77478e4a5e9f96837a386b3aabbbd",
    "0x5b69589a8349b68e993438050b0b04284e157047",
    "0x5efbb1aa712333544b313fb8bb80c283da3ba92c",
    "0x5f206eaf34866c3e7ae49c403eae82b72b80f2f5",
    "0x6075960e5676dc5fcdc1aee9c334343f08a960c6",
    "0x610cc186770ee88675b1728c37689f344e4a4b09",
    "0x616e662d822f683b65a67b56ad19f0f4db87260a",
    "0x634ea5142039756816418931aab3a26e7a209f8c",
    "0x64e469f30d085fb77fef2b31a3694fb95edc4450",
    "0x64f13df99cdae313094981ae869ed5455a6edf3d",
    "0x670598df435ac1cc6c393988b098c9b6fda716f4",
    "0x679994a1f82be819c98b65059a7e67c196b13487",
    "0x6824ec7bb04ec971b94c0cf1dd86d2f8e2efdde6",
    "0x6d2484b9c9e5961e90774ff0a6d51278bd6031a8",
    "0x7096d0880da81161abd2dfe1fa0e53d5f3755183",
    "0x73d3b5c0c04da5876242a32e03d21a1e3b4d9bfc",
    "0x749b58d4b5eeda234466807659530234ea8706f6",
    "0x75303127be6f00c8ecb919fd6e177dfd7d6cd2ad",
    "0x778e8c40de16f707a967b9b829ce9d8a41a2b7bc",
    "0x77b5f3d5a658eb4b9bb244b669beaba95a01c40e",
    "0x7a56a40935d4880e48e2397af4888aa75da12246",
    "0x7ad6bbf019bdf67b26b65748a370d530ac5253bf",
    "0x7d5e397f078adaf32562f13c2b9dda66d7fb40e7",
    "0x7d8f9e55864003ad308cd52cb07eda3e0ee9ace2",
    "0x7f52e4c8a4a4535f558ef16b7051a3ad610d89c4",
    "0x8348142049d036042056854db492dc20d3cf6583",
    "0x845e1da9776c072892861e99d52366d5bafdb702",
    "0x85b433e89006e96a29e657d2d56dfaf56fc57810",
    "0x866bfdee0d0dda3f5cce79c70b32ef5887f271d4",
    "0x86cf890e7712339d24f70d9b799d0bfb28b221a0",
    "0x8720b689ca9d1550fba2784e8cd5c3b61cd14a0e",
    "0x883ef24b6518338e79409d5113d7ce4c0fab3678",
    "0x8acda6e9ba31e7ce3f1a10bfc076482a2517d753",
    "0x8c3f186989937ae9d712618dfe68a8b8952bebbc",
    "0x8da1a75608874ca5f866a8b22668c20807ea72d2",
    "0x8ea0491e2441efd39e80ac03633f2ca9b2c3e73a",
    "0x8fc015f2cd9707361528bce580593f6fc26f0adc",
    "0x9070dc544131f82031398a6286ca2b4ea2724c72",
    "0x938a65d95fd0e038e275cfeb69e2cb4bcb432c81",
    "0x951e7e5b5ca4c96821e6572323daa1b946cf208f",
    "0x958d8b84abe05806f7ff2f7d637f9c6c9c82e0c9",
    "0x95be1b88ca0b875583ed5acf0dab5f93fcd802be",
    "0x9640d1cff2585bc06d4baa995288ba1d7d034271",
    "0x967011aaa4052d43f224ff8f46b5cd2eb3973842",
    "0x9a4796c8d59d2f156b6521fc1c885b8f1e2ceccc",
    "0x9cb35f0210ce16882a404b3bb14e0c70ab9c879a",
    "0x9d7572b68fedd268d9cd914707c9f566cf345a8a",
    "0x9fef5892be1c331426aeb4e6e32aac3718f96e87",
    "0xa04f9f497aaf27fe9759cc136c647798c2255d7a",
    "0xa0bdf16f3c91633838ad715a4bc7e8b406093340",
    "0xa194557e55254e6251986982e63a0c813ed18758",
    "0xa236ea2194d56d54874730dcd2de9a39a47f57f9",
    "0xa2b6293917afdeac378fc1c32f62d2d896edff2b",
    "0xa40e85e53b0b8db272ecf39c397b5eb1ef07c9b3",
    "0xa450fbf84a27cb42ce08d927cf050373524fa172",
    "0xa524eff263e0ebe19e0c0652b8334f9bb29dd7cc",
    "0xa6eec510e6f1010b59b3fd43c60b4f54c14f243a",
    "0xa838c04583664c36974417c6490fd8fe830e3482",
    "0xa8d08b7aee657a3c51367453036030824d79c961",
    "0xa925e0bbd45f6ebb66a876f88fe8b12fee345861",
    "0xa94a0084f620ea7265cac8c91fae77415b1c5b6c",
    "0xabdbe9a32fdf17e034914641106b46c65d79921b",
    "0xacec493d656eccc607ebc3b09951aea6df73b401",
    "0xae71beec18d616d5625d61d7932d6e681efaeea1",
    "0xaedb2c97373d6e9f6a0701d582ac95dc23878976",
    "0xafd56aa1412ced162ce93bb2e4091207567f2171",
    "0xb0bfa24a73936d3b3fe05387d89e8fbba363c393",
    "0xb32bd383a8565d8f8ed0db0dff4e9f1f7a6dcd49",
    "0xb340d9f239d101d8791ebe3add34675ebc184941",
    "0xb3e4d27e0f37d5c04c9bd609c2cefed6bb7afcde",
    "0xb42fff8990ff094b03b146df3fae94742d420642",
    "0xb47efbdf4eccf9db72db2792af7455e21010cb02",
    "0xb56cec27536666b3f1a334428ac9c0c9532bb56f",
    "0xb6b282d75ff8aa7a1aad9f806a87151320edf5bd",
    "0xb82825d2820454732dd72b1aa3ccdf46d28af4dd",
    "0xb856f5b77f9b2cb4b0700f979375c236e17519c6",
    "0xb87960d3f0c232190e8a44a93f8d04c0d78ccd4a",
    "0xb87c9f2f540dec1d9869329c756353487538b9a5",
    "0xb9630ea1f99f8fd84762a565f2b27bdb7ad27e45",
    "0xbc43c2cf6d4feaf4c1c88074c9c60d43e34bdabb",
    "0xbc6d77aa4fc4407897bac23d78dd4bdc1d5f8676",
    "0xbc9cd954d21b1fd5bdc36f73f7685be29431394e",
    "0xbd8133a569683f4f2b90d3776a254191b0d90ee9",
    "0xbeda85d41152b2eb9cef6d24fe6848c3ec262da6",
    "0xc024696b5f0836f745fc85d39415f1aa12c1b8f0",
    "0xc09956866623ac5c49cb543634b0a38a6eeaa862",
    "0xc19f233a15bd6464eff6af3c78f70037233a9ac7",
    "0xc1aa76389f5dd58690f8b009575d629b8501c787",
    "0xc53e5484ec9d02ef2e599395f17a77246be3fd91",
    "0xc55a113c44612242298cc6fdfb51fac9f3b0d6ee",
    "0xc62c126530da92179d2d3c2fda3317850a14ad33",
    "0xc8a064829ad6295adebc63bbb8bae89908610bfc",
    "0xc97bfbf852d6c35e0a751dea83f6aa5043ea9f03",
    "0xcb320641f8c1aeda102814c5befb040459af25f0",
    "0xcb57332ca46fc333ae8cff1e8c485aa507c9eefb",
    "0xcbb28523892a7b0e53927569108e6281c7a26a01",
    "0xcbfde37eb0222090bf91ff4700b7aecda6bb11e9",
    "0xccec5d37e7c45ab4beb5ef666f217038f5337389",
    "0xce104b33d31a9ccb66a43b72f9d2cb84d9c059da",
    "0xcf23b1f68ca01cab0d77faa5332cd55a738e1ae9",
    "0xd1032a082da6dbb606787e377dc77ec57d50ab91",
    "0xd25770734598e18fec82c8dd6b29b1fa84bf18f4",
    "0xd2ef23ea827e80d88073496c568543570caefccf",
    "0xd30e17a73a788850a3a372393ceb0036406467c2",
    "0xd40e246cb7585b5c8660b81004f3bc7e00af082c",
    "0xd453b6fc0c815fbe292eb6832d0cd0e518733fd6",
    "0xd47f9259845cf7ef4d392f67cd3858aaecb1f329",
    "0xd492eea6b5bd2ef7e61fb73c26d504320ce89168",
    "0xd5ea828cb08d883997a4298eee8ad8a4b1e709a7",
    "0xd6569e83ccbbf662278d2104d0e551e4f7caac1b",
    "0xd6e1cb94befc8d26f44049174091168f97abe372",
    "0xd7249989d9cae25880d1cf560d6f6f38b9fb6f89",
    "0xd928b1ff12fb67837c336c65375e8a2e08b5ca41",
    "0xd9898148b1299d6c5e0e7461f026c9069e3280b7",
    "0xdbc29240c6434ceeca58f28d5d9e9402d9781e4d",
    "0xdd47be73fd91ece2499c4500d0948f70e6b1c3b4",
    "0xe07e7da4227ebf6f2bfab62a3263f54dbd49db4a",
    "0xe091ea432467ea3029b38321c8f98994207f2239",
    "0xe28e8446e049fb79b5501ec5f467130ad9194c9a",
    "0xe3cb5669606b1d7d60c5703e68ca85c12d399a7d",
    "0xe43addcd32b834e8ad3f5b9c0f9fad816c146891",
    "0xe7d11c8601ecd7cfbf3bd5b0f74157773979ea84",
    "0xe9448d94f5f7ac4af563cf47eb4a906f11632bc6",
    "0xec55aca9d63a099c539007ab158dfa4a30002003",
    "0xeef4238e3faf73e160544b9e2410cd8efabc11e4",
    "0xeef79a16c20774d9abdab78e6e34f12be28671ce",
    "0xf0a23d6f277e11ddd21f46fde34ac23b405885c8",
    "0xf2a5064a71b3f055160b2554efaaea9be75b5170",
    "0xf2cb05b0ae3923c3b6ac786542ddc3722eef8c6d",
    "0xf7b861fba42096fe5cf194286e9a4786230a1e9f",
    "0xf88ba999020b7bae8186efb2a4912038e6cb7ad6",
    "0xf8ad3ade2d9f7d2128756b5d7c3defe567de5125",
    "0xf92e4615cfd2390176d6ddb059743d25fe428ea4",
    "0xfe41948cb8467e835b0865837b354134bba4c5f0",
    "0xfeb9c1da4305c10f1d6d64eb91c3001237d921f7",
    "0xff79154f19314a58eebf40e7d6a94690f9784d00",
    "0x3c9f50c9d4be35c734290b95f563d4dd621e240c",
    "0x3d5d03b98a0c46b014b20670e66ee16983da492a",
    "0x8921e4e7d8438f86552e6e716b05e7ee289dd8e0",
    "0xb0ae827e7a8c4b4eb71d4119c32a250333769364",
    "0x6e1b0ac7dfb69e9b026863f8af2f6c2850030e57",
    "0xc0fec0cb111af730ab71ae17adc89174fa25f7dd",
    "0xc2a9d421ff85c8211ca8dd003cf9e5c91bd1e7ad",
    "0xaaa3bfb53d5d5116faedc5dd457531f8465f78af",
    "0x8a71a81f119d85f0750c50d3abf724817b8c7b6b",
    "0x609ff8f3be3051fea248908ba272d5cd78582420",
    "0x0c122b296147b20c0d076ea9443b76f46649aed3",
    "0x2768cccf253d25c8575441fb3ad48a6d188a4519",
    "0x2f80f930af7ae24905e1dfc153d11d3ab0b0be5a",
    "0xda42235b31c61b8535563f31b8683ad3fa8c5b94",
    "0x59b3b654d20162ce340f13128c5be88e97f93cb0",
    "0xd4e5e807dad8fb5593b9be5a129355e70a3f5ca0",
    "0x31047ba37a9df3af44884f8e787321e4c0ba8a6f",
    "0x9718a8fa641a1287715148d696aa99d44dd68622",
    "0x081af7b273a37927bd7f2fe14a7eff6e5d9b524d",
    "0x77e76ac6c1ed23e723947f7a2fe2f6c9c8347ec5",
    "0x0d5fda80fda27ea65b0d9e5029a90a50b8b88e57",
    "0xa6dc590c7d076bc1b0aa8675486592c0293279e9",
    "0xe1ea418f48aa4af23ae9652e1af392380c360ecc",
    "0x1122dd10264bfdc961e4831167783200249564fc",
    "0x0ddcc8881af0156e8f50520e13797ee6510fe9b3",
    "0x26ed53586689bab3c9c5eccd974a2d9b32ccbb9b",
    "0x358e2ee46ab7bf1c9895092a855851c8f80ab6c4",
    "0x53b581f0f916e09ba07b6204e8720a1d9323521a",
    "0x5700c4e92407979a1a7f2d6c2cabda0ce94fa8dc",
    "0x5d30dc36255ae4ad6022c7b8ba640e709993c4d4",
    "0x990e174b2a646c7cd191742f29a08e849f14c705",
    "0xcf257e3fed007e9d0a62a572e903a8e110c1bd2a",
    "0x0ad8f933b144c7826683aaf276466daf40c2daec",
    "0xf296a1bf52dada6c37a7170c1e808534206f9bb1",
    "0x541893b35d6cff9a3c2bff28362173325adf84e9",
    "0x5d0ed3086857bc36f6af403110c8c3715658fe7f",
    "0x7c6dddf8983edca1e21850ae4652ee5b26ae3152",
    "0x7dbdd67a992b801d0b2e1b4b417c537d5f3d887e",
    "0x8d4938202144ff2a5dff3598b5a6775d41813368",
    "0x8018164226a0258dfb63789408c35b103c527a86",
    "0xc8aaf448866ba155f3aae5fb5e2e364162afbd85",
    "0xe31b2fc29c29831be58be76abe4106905dc28b7d",
    "0x4431964b753b0112e8dfafcf8f0b359ecb78e3f0",
    "0x77c5ff531461788985caa27d475ee99a08191ebe",
    "0xc429624d705d13dd34fbd66a751bf14a9a770197",
    "0x06519afa5ba67e9aa5d46fb230ec187234323677",
    "0x0c8343d9794dd99e7d2d4718061b99f8c11eb60a",
    "0x897f1569a22ab89ed2ed363acaf9b8613edd27a7",
    "0x9be06783d8df6f483071805311e3dd55032eb88e",
    "0xb6ab6ed99c21a7317c607e9243510d89b6f4b91b",
    "0x03848eb02c4da4f8d834d4e45a6d8863cc0a137b",
    "0x07639b0592a6f7ecd354cb96c5773f98a065ceac",
    "0x0859c6bc80cb09f5f1325fb497fd43b47ff748fb",
    "0x0af4908e085bfd29d083cc8c8fc051dca7f62d34",
    "0x0b0889e068a806be27ffaf1c2c73c805fe9116a9",
    "0x0bf5cc779514c0e8d7f4cf247feab7fb9aa069c0",
    "0x0cafb65d49d734fc49a51668cf96188e4bf595b4",
    "0x0d27da33014c55a18c93ee42962bcfb5ce64f162",
    "0x10579a47a9585d71a102f59e76f8b3dee330520f",
    "0x11a6f7b29d17979b3a0c19287d2f1ef415583767",
    "0x19196fb3ccabe4731ad10d43570a81ff55c4970f",
    "0x1dfcc4c5af74dc92ba1516d93a65d048c02627b6",
    "0x1e2ff7821ee62e3cac592b5625a7486e9e995b6f",
    "0x20f0966f65c710e9c623e1961c4c9d6193a8abd5",
    "0x24353dd9ff549a870c1d2611e2763ff330066cc9",
    "0x251fdf6e132ed2bcd1f970dcd690096244851cf5",
    "0x265a632d33d5603f75791118b791542fc4a26eae",
    "0x2f5d18651bca56c1df3fb0a8df223b0f4fdebd83",
    "0x31664f84fd99eb3c673de4a544978094dd9a9c00",
    "0x34e12b4f69503683017b51bb00fce7f41092b739",
    "0x36f2843564480c80f6312a335780da8cacb3a583",
    "0x384bb54891a53ba48682312b473727f51d95d5d5",
    "0x3a5e866502d180e4f8f784eaae953be14de6797a",
    "0x3b3df8d0d28b222bc26c8eff4bdc2cec996f73f3",
    "0x3d54a1fdd308fed2cf73f61472421790732dccd3",
    "0x3d8aefaeeee0bb106f588c58268abdf0866a68e9",
    "0x3ddffb866018e93470f07ebaec2bd07242c61b45",
    "0x3fdb30bd75abf385bcd00a3a659a7babb3f84547",
    "0x450325132ce3c51d770d0ef7ee342e4bac943669",
    "0x4530aa02e12fb217e5f0f0f2daca3c4c07bcc32e",
    "0x45e6fa1d2ddd764e5e0a68a89a2a4e5ab4e29857",
    "0x465d699efd6657e7e78660f7fd5b82e4657a6cb8",
    "0x46f0e9b711673eb42877ded4c6bf1d27534f1054",
    "0x49ebb261d20f58e6a9aaa6de6b10e9b49046f8a0",
    "0x4c99915c161b9d398b0aeff0f7a196b9ba77a150",
    "0x4e61ed532d7880ea9faffba4f61a0e95ce51eefb",
    "0x4f5aca471444007ae4b6620e35be3756fefe676a",
    "0x5155fe4111ba64acd58c8ac1cabb9691883a9639",
    "0x51e7dcb835b9dd9b3fc3f90d0c70e550ac6221fe",
    "0x56122cc438a351c29458eeedd58f6121b48e19ff",
    "0x57225774c0cf3f51b87cc8965888428f7f30f4d4",
    "0x578553f6787ed983043a68effb7934d69abbfc77",
    "0x589225dc6009ca0ee8d1a6a65ad4f4579d9ab9c8",
    "0x5a71ba7cfa83bc9082cf0a910892b778e0f404fb",
    "0x5ce54ad911739d943c35ca02e818a62a9726b67e",
    "0x5fcc087a3c05b4ccee3bfaaaa5a93f483ce534fd",
    "0x67e2c6226c8ec8acf0e0f14b0286f6d12cfcba66",
    "0x69c22ede1ca1799c165bf9cb4224b4600f6db808",
    "0x6a98f96130fd0ffa4ebadb3890f03aec44abcf04",
    "0x6b583b5cdbc764ebbce08687a3652fe0ba8725b9",
    "0x6dba377083439614bf94cc599beb0b514b657bfc",
    "0x6e3d51deda84a7a40efddbccb0ad745e47558858",
    "0x718e852aa6bc99960d601deefb859389d934debb",
    "0x7416e9aed898d3747ca1dc693044b63851baf305",
    "0x779b66c88a799d57490206b701bf4af6ebdb423a",
    "0x78002d09c187fdb21af01c0dfcf58b39c087a088",
    "0x7bf271ca1fc51417dbf4ddcd9625dd2f2830ceff",
    "0x7ddc1a536f472fa51a6a62197b5f12a515ca049f",
    "0x7ece907c5bca88578e9fbb50c4d4d3499bee1e1f",
    "0x81951c32b4c9661da409b0091c2ce043efee42c4",
    "0x875ee19c25dceb4de90b19cd0db570cff750b2f7",
    "0x8f2bf5c7393f9a125a0e92bdf3390d9374a8d299",
    "0x8fb997ba80e85dab1531f17827cd82a824db64f3",
    "0x90e14a6f9c9625feee59fa83287bc411d7d282d3",
    "0x95929f5f6351c103a1946a30c929a2be0740a8eb",
    "0x970c21da3d93b5e94d04df8a5e024623f91183a9",
    "0x97976e9421ef913f5bdf31e0be2335a30eb4ff81",
    "0x987afad1db2ff3be2d556a7d92b2e9c3591cdb37",
    "0x9885f2e5c68ff94e6ad88def8b50e3f6bc68a036",
    "0x98ec5fdf07125a0554481bb3ce47a8402d567a12",
    "0x993cfe9d75c5f19ad1ae4ed9a9c834f95e576865",
    "0x9de305a49ce1defa8f4ed0c369af1a44d9f33893",
    "0x9e15234a947fda0f4be3d5e3c6969c0e82c56cb7",
    "0x9e3b107f9c766295adaaad0d0995a4f7782921be",
    "0x9fa9d682707fc31303e0a867bb254a7aa4d90f9c",
    "0xa03e7f6495bc3177fc8be4df7dac9599534d877f",
    "0xa16a27bb0c9c800ee7ba1ecefadbbbe815e64d28",
    "0xa1cca97078763789d6cbd296f82c53bf1ee2858a",
    "0xa286a435e37e51b18dfa8dc44a2788f07c1e8a36",
    "0xa579b6e6d2a6ba7d26eff5d746095b0c33298db9",
    "0xa98d8f5010447dd410be425135a523fa949e629e",
    "0xa9c8e56a091d8b1daa4441177ff555f686a4e011",
    "0xab2e3e8bd92a15a4c652277b568f177b6191a890",
    "0xae7582277dc0969f56f736434563d40795e8883a",
    "0xaec9599d42d468b17949fb84158fc70293306327",
    "0xb227cc099094605faccb8eb0580efeca0d2d20ed",
    "0xb2a551411a19af9d84681b438f10687eac43daad",
    "0xb47470d6717b486ca327e781712f64edd1df7987",
    "0xb78959adbc70e51378043878697378c483e65355",
    "0xb97875896341f2b0ccd37888043d159d0b539ac3",
    "0xbdbc9e06b4a752194dc80b525c8b88fcdf27f7c0",
    "0xbe4209816604fcac1ebd2ec6a56d36afe4adb893",
    "0xbf9f3ae29f7b416cd531468a737df0884a966d3a",
    "0xc80287bf99c55213177da17a0ee53919e5fe578d",
    "0xcbc0a40e72d25f4c3b3f076e16430c52bd23a70a",
    "0xd5d4d3c072321f87f198879cf3bfcdc1d8df1395",
    "0xd721b743783c0a1f1a7ee0161663b780e9f52f47",
    "0xd7c9d33256f34353324aea42cc28471ca9d6c9e4",
    "0xd9ca57d404f33b815801d9af83ff7474d2b14fd2",
    "0xde770a63cb126282b14d4e9a5624912847f2ccdb",
    "0xdf6b61a861bd36f03cb5140faf73286312f98690",
    "0xe0fff871840aae7a4dfadc662319bca291b5086a",
    "0xe2557bccca5970fee814726e2a4a5b9aa3e38758",
    "0xe461a6ad29afe787fb10ed6422800c16d9c6908a",
    "0xe596ec5462ec8251b7ff12322dcb4c8492c08fe8",
    "0xe5f9b054544e6b360010565887f806933971ae9c",
    "0xe79a2d44f389cde321f5ed37ee6b1a24d4f56017",
    "0xe836df072655fd68faa8e8f0c471b565b06a53d4",
    "0xe87f0744db06583ef27aae0f4c0e493692fd3c7b",
    "0xed0226d12a31c2ec53a3c2bb819023a9c752c003",
    "0xee0b618bcd18f798d3b15b0dea961831efce1ead",
    "0xef9e34488a549b4e8d86aaa673b4546c79f871e1",
    "0xf0c15c42d12a66a64c18b7b3aaabd301850c2b67",
    "0xf39abe513ff55fa342ba3656c2659e8dd80a3f7b",
    "0xf7db83033c48f097b8b47dc9c32e6cac2e28df8a",
    "0xfb616fedc2c9646effad595399e8ddf5f8c102ee",
    "0xff059d87096d1edf40ab0af9f1bd32efebcfb386",
    "0xffcb87e3f0d9a464cc53c95422cbb9b5b95f2b7d",
    "0x02b129aaa8b0269ee179898bf716d0678735fd8d",
    "0x2d632a82bc1784e0d8b610febbf2c2875f676221",
    "0x2f944738905f93ec3fba145463e0854b7da77f71",
    "0x3fb80007367f16ade9168a3be2fcca5c7f357cbc",
    "0x46bdc564bde98dcb06dd9cace15990b0b53d5641",
    "0x4fbcb822e44b1103b403320d42aa23f19b5b46ce",
    "0x509e066a14eb1c3af3ad9085b39567c7f4dbb342",
    "0x55cedd0093a10536eb82a8ff3224ed41b030e3e3",
    "0x60a9864fa1eda66881ef128add3eec3054ff1e39",
    "0x668d0c0e4a354a9927e791b45bdf24ae20319b22",
    "0x6b86007f0365061b9d51c9739adf12574f1a56dc",
    "0x71fc301bd42723b8cc9ef6af3041047fbf405ac8",
    "0x83cd0039a7c23e8127c5a7c50242b3f68bf0b5d3",
    "0x8be7021c5b857ce50db4d83066017abddb559d57",
    "0xa9e91055106a07620cf897dd3656223e103a1e78",
    "0xaf82e8de3f1b37dfb523d3b0ec4971a03d2af30a",
    "0xb95c88262f6632e4b7c94432b6a360c19e642e10",
    "0xc41acbbd3f308068baf5a2202e43efe0965b21fe",
    "0xce5154b9e100c2cf4acea852ccaff06f7a791af0",
    "0xd7af5ea14fad145b2d9fd57e321d7bf8301980b5",
    "0x061f6b0f358e70271f04dfaf7f21e14e46cb69e6",
    "0x12e54bc919ba8cb3bf9ef18926d8667ede2f436f",
    "0x15c64c9000ce544525a93ccdb87f161c0368f80c",
    "0x17b7588378dcd3aea37960a7bb316c5829056b3e",
    "0x3f7db277638df8d2bc47e9f4bb8da4dc54f94ba4",
    "0x628e8030fc72c7ae9bd1a009acee04e690901023",
    "0x679994a1f82be819c98b65059a7e67c196b13487",
    "0x6b85b562ed739c8c434dbf7c0b9602e3e37dd53b",
    "0x6ccffc1aad14cf6c64ef3ef7f0046436ceaac563",
    "0x9bbc5536b833ccff5b4a50b65b07e78a85718286",
    "0x9ff04b2db76ceade3752a294350c70a23e3c1762",
    "0xbee1f7e369b3271088ed58bf225df13cd96d32d5",
    "0xc64a4ad8fe5358604e62dece47ad5e591776fb94",
    "0xcd1faa80b3292e16347f31590018446552c6708f",
    "0xd0900b083015ba4be898541f60f61b8e22b3ea98",
    "0xe7ade8a4c07c684fbe842a533cf35d551f4f378a",
    "0xf34101494f7b06cec2cf270adf2d63859bb88fec",
    "0xf57414a6e5bc46931ab9cb1e654215e2e2017115",
    "0xfc3b48190541657da7269ac518458b26a718038e",
    "0xfc3eaf0810fea2b68755d300c926f104997aa27b",
    "0x0277d92b437d0380434ac15b74ca19a7e154f0e8",
    "0x03690abb8115b8f0d9522f839ccadaca084402bf",
    "0x09eaa08f8e288d34d416b92c53cadafb5cf1209b",
    "0x126bcb3dea3d74d240b1655fcaa885c9d44d8a0e",
    "0x14722dca36f7dd106a9e26163a5b9470bc7724c7",
    "0x1ecb6c568c7ccb56ad3a84b0085c82a21e41fca1",
    "0x22a8407e6fb6a1a3bfce5d46725bb10957ec2a24",
    "0x27f3adfa34f1e773640a148c2fc614bbbf98ee1d",
    "0x30391fdc67b3a1427fd4fdc2d4e0875a8a1e3bbd",
    "0x322dd0bf7b020e28009d20770e2ef04356961196",
    "0x4970cb1bc7d40e85f85b7c5d4238b0715eef0636",
    "0x4a0d922f5177f4518a19e4962629c0833c7c4a73",
    "0x577acea1dd17fcf6ef7225c4cc4257b999c54380",
    "0x59e315ab18a9e3702f07b531759beaf442598b31",
    "0x6dd09de8264697629e015f6c1629ddbc88558071",
    "0x74456cf397975601d7924ee113bda64f9024efab",
    "0x7d0548adb6ea07ffb71eba2e78f91f1f03dbc950",
    "0x7d8d77e614a976650f8b4f4db3fa738c12aecb0c",
    "0x8e5421f3b2e6af9f5ff2fade4f16478414319b8b",
    "0xa018bfa77bb0469f7f3babceb6463fbcdae51e06",
    "0xa31ee3270d637681488a33d9b5f4d2cd6bb9238b",
    "0xa636821ad243148f9058fc3e0f329f0e2b8695dd",
    "0xab31af4109ffbca760366c801c94daaa88888888",
    "0xabfb5d47ad3f12a40ac9d9c921d3314e811dde24",
    "0xbe1bff5270d600fd06009fff4b72140442c6af4c",
    "0xc62c126530da92179d2d3c2fda3317850a14ad33",
    "0xc92ac7c7284f6ad8c3f5e8a5db1e993f34fd4e11",
    "0xc9405b471c64769c5bf588281e13a15dd411dd2c",
    "0xcf0fe3d37413ad7f746dc1026124e7ff150e6c08",
    "0xd09a91b8d21f682b4e71d7a1847efcf02a9b48ba",
    "0xd3e4ea3c8d936df7ec26361dbfe73a60a086e12a",
    "0xe7bb2125cc6310419364e79d3d7cf74c40f54042",
    "0xe83f43d818dac59adfb292d1c1751a4341061c96",
    "0xea6c59c77de39701283442b0d008eab4ce338ed3",
    "0xeb9b9acdf515d141e818ab8234329e92775c8603",
    "0xed74809eaa4a3d44a4bdbf49cf72f34119e7d325",
    "0xf42dfde700aa23ad187f2530f4548d6224c7ca56",
    "0x006abe463c1d6a6ec1ece0b2afe7415e29d0d2d1",
    "0x00e484da1156202e9dd341ad7ea9c908bb919e96",
    "0x03ac8a524b465737cc3b017a95dd2d997649c683",
    "0x04b4fa4d9926e47a8e67528a4074e39b8a52edcb",
    "0x07529ec8910db3409318ad903342e648732f6621",
    "0x0930ec1a67856b37c6fd49c8cc5225c08844f55c",
    "0x0971ccf9d28d09d64411d47cdaf210b6e5826731",
    "0x098c02e904a8a008fe7b0698540840a809f2795d",
    "0x0aa9b164bea1c9a223834cfc899b7130fcb92f6b",
    "0x0cef3f0ad32acd2e2a70b76f9fd24d50d88b195d",
    "0x0f9119d9fbffb82fa094c8734661b4319ef7a964",
    "0x10c163364fe153cadf6532a5b34481eefb99d96d",
    "0x12a59989f8f06130bda8d6e936b2045c734f19ac",
    "0x13f7f55d5cfd5aafa78b85c6c43dd670faefc7a7",
    "0x142cc94ba3b7ea0d77d06cfc3fa40aedc186f601",
    "0x185efdd50f2a22be469f741ce07fe2be9b819f91",
    "0x191f98ac57f12f4be345c2da34249ced2bad5865",
    "0x1a4933dc62593613afddefa095a07665d69a8561",
    "0x1c28f041e4d70fecb05482a669f019377f1e20bc",
    "0x1c43c8e43fdacf55f2f7ac44768c2bef17d02258",
    "0x2491b933854409eca08a7899f64e06fb3a3ac4df",
    "0x27be3bae241df173585757154f6fa489111dd262",
    "0x2845633f9cd219b43be29cae1ad4b776865e51d0",
    "0x28a557349a0eadb529a68bfcd346022cc812acb6",
    "0x29cf4c62fbb6bd5cdbd0fab6fb301e5196693861",
    "0x2cc21b6ce4f88835e87d624e8d7f61cd6392cbdf",
    "0x2f9129fbf0861cef35093195a0e2daa9a0271ff0",
    "0x2fa7854c06ffaef353b526b05e424a9a3fd7a4b7",
    "0x31cdf36a1f86f2ed71449da58afc94dcd6786c18",
    "0x331017600f8d211462e60e664a5c3332b0f77353",
    "0x33a39f6e1713c7e847e07533790e8ccddbe9db03",
    "0x35aa7d7bc64c2b03895ee6315a14944ccfdf9aa5",
    "0x361fc0ed3d5a3d0bdfdfde855e46d9c04de6b7ee",
    "0x37bfe406209dbea228425debb8a96d4748b6c639",
    "0x38201e227ec0624906f214f4aa4fa6f4cf0d1d76",
    "0x39e3d2cd89520e2d4754b90e34b1d6fb0e0f6aed",
    "0x3c0344ea3b5f3af3d55c833f457bfb86d9d60f2c",
    "0x3ce10c285fe10bba27dcee01f3a6e8e875b8f7e2",
    "0x4449293ff61efd25181031e8d779df883e16a3f0",
    "0x448c51512043cab1da6db9ec7b6e7589f3496217",
    "0x4638fe916942098eed3d9623a56004ac57a06934",
    "0x49a102beb2885615c6f4442ade430c1b808f8748",
    "0x4c2d189d5aa5b451fc7d723686facedc5d88f8d8",
    "0x4cc336cb3caf22cc41264495c06119cb681be698",
    "0x4e8d2f44b563a3ff93ac07dfe76c9d603be9533a",
    "0x51f1b15007235429d8a30a73f9669a4c7c0af2d1",
    "0x56d862bd9a95421936ecf3b1f0291f550c01e5da",
    "0x57147092ef5a1e715be524b5c980ef720cb18494",
    "0x59f0d539fe58f602f09582c8c5c93a6d3c801de5",
    "0x5a2079450f5d9abdea4996e88e6710f86838fe0b",
    "0x5d731a3eb587158eb9475214155af9ed4e09226b",
    "0x60ff0be64ea678545b7a3b35c9cc0b69ef07ee7a",
    "0x615dcdfeb84831ea07c4e7172b4132bafbc57719",
    "0x62f65fd686b65ed36953347779f8168ba83ce2d3",
    "0x6e63c5f8f18689cf99e2e65938ee61cfcb9d9994",
    "0x6f485e7b9b398cf34884bdb52a94ca9dff386f23",
    "0x70835cf6e316ea9e9024080533902faa0757e7ae",
    "0x723ad9af135d0cda81cff14598053af42ed88de3",
    "0x736e0d5563f5e5cb9335a0aeb1794546a9908882",
    "0x73fb71642d9189cf8eb197ddeaa46ca962abf8fc",
    "0x76cf26f1c313e759855eabc5acb96ae4a0748676",
    "0x76f1441f942d50cf19fb43abb78e3977cdc06ee3",
    "0x7a9d559480ec5d7d460988755b36eb417f4ecac3",
    "0x7c64e4d3fcb3e4bfd64f34a736f48c4dbba3d33b",
    "0x7db4102f8e5fdb6835fc4b414858c7fb3b5ee62d",
    "0x7f2b961f89a0258057e63ee8d2dc0af2d413cab3",
    "0x8423753fe03a4f0acf792e00426bf6f758ae645d",
    "0x84553ad958a3ee5ab45d3ea1d10ccb7e72b3fda0",
    "0x84c2ca57829e989889980543d27fac49cafb93f9",
    "0x87c89f5f027b0dc4626e92d92e5964ff8661df84",
    "0x8a47f1c74d3a5d918c58ee95aefa623be523899e",
    "0x8a8035f056af830b7205c58c1dc037f826fc2b92",
    "0x8ffc9f7f70083282d2eaeb45423bd5f1235e7671",
    "0x9210195d99ed16e04fc34b75fbb8ef6dd226942d",
    "0x97643e7a56fee4b9e34d731519881aa09569fd13",
    "0x98608cbf3be5860d964c49449a9b538b2e5f0b0c",
    "0x9a4519c58900276fc4a93f7ba7009a5f36a9c3c2",
    "0xa0ff0320c721d95d2de8a4c30ed2eeb3acb11332",
    "0xa5967bfdf95101164bd7055d81aebb57a321a046",
    "0xa5a8ccafbd6590d91e36ce1f9a4839193dd9e20a",
    "0xa603c201ef16cd3f021c0bc82332229cbdab35c4",
    "0xa67fbec42037e1ce34e0801eb8aeaa4355e5ec9d",
    "0xadd0c6134f28a4e74c829540f35a3194af4c076e",
    "0xaed9faa8ce30d256720e6269e8148dbd36a00a6d",
    "0xb327df4415ae7cb806687ba082478b3e3a73afa5",
    "0xb56a8480db3c68c72bb23c2f1aa7ddfb4036b2e0",
    "0xb573d55bb681b091ca01ef0e78d519ed26238c38",
    "0xb58f78a02362211532578eae09245c9026578e00",
    "0xb76a5cc41f7e82455e1115664e3390dafb509dc3",
    "0xb797ef967022a0b8959d564573536c951c626488",
    "0xb9ccc3b758fd5c7f3e6d8459a936cc1b971cb52c",
    "0xbe5838b07eed79870f39e35e87db655119ea7346",
    "0xbf661fd2b1c045409664a31129789c05384a7934",
    "0xbfa4670f66a86848ae730743227a4fe51f4d39d2",
    "0xc024696b5f0836f745fc85d39415f1aa12c1b8f0",
    "0xc2b9ce58dad4def72e55f4ff2c36b20f23cc8621",
    "0xc6167eba77c78b867a0b68515abb17ae5a1ed639",
    "0xcab6193901887968cd656bbb15ffde19b0eb1c37",
    "0xcdd6d919a40e60962ca3c96445aac9e078da2ebb",
    "0xcfa10eb34cae908e6342caff77328681f3a6404c",
    "0xcfbcc0a74cdd16418814f5bf59cfa94017c5dc4a",
    "0xd5ff82fbd36007cdc40509543aa3a4f856ca2b8c",
    "0xda555b53128f4a54e31df690357f7acf84249ccf",
    "0xdadc55b8ab58ae10ac3c5e8b2d759ae6c41f0def",
    "0xdbe38ddce38db951b53b9c61909c13d7617ba847",
    "0xe08ce686cde6c93e50c14508b8a0694e1a5fbb2a",
    "0xe0a749772f7512983759a8a7dee2f5a39d9ad14c",
    "0xe0c85d006773170bc956eade54446f64370bcb67",
    "0xe15ae90722f476d1ef0c2ca66f97a6705416b502",
    "0xe181725de73c2a58325e5b08af5524a7d81fdae2",
    "0xe3f34370d93c64a4660ccf3207f40348b1198ff7",
    "0xe97103c3c8aa58709c2920c831d1baf97d5dd5d1",
    "0xea5876991ca48e366f46b5bde5e6adcffa2000bc",
    "0xeb6f73d3c139e8f53d2550c54687c58b917ce78b",
    "0xed6f80adfcb21e37034d198c3e783eb42d19cb98",
    "0xf0fa1a1b08aa526c17593c23b7f438125dc6f693",
    "0xf1f1e267a26648cf9f39f0402ec75b72cb292587",
    "0xf28cfcedf9ba27718a223ef5272201ae46a33cfe",
    "0xf41c79cb6f80efeeab7197800848e9b5a8af24fc",
    "0xf6a6f20a28e620ea32d4f41ca840b9e3e76e16be",
    "0xf6ac9ef1d7f29d257e91dc3aecbdd4a53fe3bd16",
    "0xf7de9985290dac0ea74f54d7fa3832bb3e5d9e2e",
    "0xf889f0deed5e30f4bd6ceeefc4bce080fe2f9442",
    "0xf904cdc9d8e327ace99307a8ccee36dbf91e46ce",
    "0xfdd9f26b19fa468668a3c50472b6cfc6afc32cf9",
    "0x49e2BA2932550Ae44223d2282Ade6DaF7Dd35c66",
    "0xa838c04583664c36974417C6490fd8fe830e3482",
    "0x8F26B0DD150A84cE18b40D6da15F47DB6e27fbA3",
    "0x26A25F95f321D0dC2988651913b607B9017fe4a3",
    "0x170FF879349558Bb4b1123525e7921Ac053a6E78",
    "0xce5C80F38F01E4a29D9Bd1F64Bb7905B3Da6c9D0",
    "0x3D757454ea8eBbF056b618A048C153edDB95b038",
  ].map((e) => String(e).toLowerCase()),
  tributeWhitelist: [].map((e) => String(e).toLowerCase()),
  abi: [
    {
      inputs: [],
      stateMutability: "nonpayable",
      type: "constructor",
    },
    {
      inputs: [],
      name: "ApprovalCallerNotOwnerNorApproved",
      type: "error",
    },
    {
      inputs: [],
      name: "ApprovalQueryForNonexistentToken",
      type: "error",
    },
    {
      inputs: [],
      name: "BalanceQueryForZeroAddress",
      type: "error",
    },
    {
      inputs: [],
      name: "MintERC2309QuantityExceedsLimit",
      type: "error",
    },
    {
      inputs: [],
      name: "MintToZeroAddress",
      type: "error",
    },
    {
      inputs: [],
      name: "MintZeroQuantity",
      type: "error",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "operator",
          type: "address",
        },
      ],
      name: "OperatorNotAllowed",
      type: "error",
    },
    {
      inputs: [],
      name: "OwnerQueryForNonexistentToken",
      type: "error",
    },
    {
      inputs: [],
      name: "OwnershipNotInitializedForExtraData",
      type: "error",
    },
    {
      inputs: [],
      name: "TransferCallerNotOwnerNorApproved",
      type: "error",
    },
    {
      inputs: [],
      name: "TransferFromIncorrectOwner",
      type: "error",
    },
    {
      inputs: [],
      name: "TransferToNonERC721ReceiverImplementer",
      type: "error",
    },
    {
      inputs: [],
      name: "TransferToZeroAddress",
      type: "error",
    },
    {
      inputs: [],
      name: "URIQueryForNonexistentToken",
      type: "error",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "owner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "approved",
          type: "address",
        },
        {
          indexed: true,
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
      ],
      name: "Approval",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "owner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "operator",
          type: "address",
        },
        {
          indexed: false,
          internalType: "bool",
          name: "approved",
          type: "bool",
        },
      ],
      name: "ApprovalForAll",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "uint256",
          name: "fromTokenId",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "toTokenId",
          type: "uint256",
        },
        {
          indexed: true,
          internalType: "address",
          name: "from",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "to",
          type: "address",
        },
      ],
      name: "ConsecutiveTransfer",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "previousOwner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "newOwner",
          type: "address",
        },
      ],
      name: "OwnershipTransferred",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "from",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "to",
          type: "address",
        },
        {
          indexed: true,
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
      ],
      name: "Transfer",
      type: "event",
    },
    {
      inputs: [],
      name: "OPERATOR_FILTER_REGISTRY",
      outputs: [
        {
          internalType: "contract IOperatorFilterRegistry",
          name: "",
          type: "address",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "owner",
          type: "address",
        },
      ],
      name: "balanceOf",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
      ],
      name: "getApproved",
      outputs: [
        {
          internalType: "address",
          name: "",
          type: "address",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "owner",
          type: "address",
        },
        {
          internalType: "address",
          name: "operator",
          type: "address",
        },
      ],
      name: "isApprovedForAll",
      outputs: [
        {
          internalType: "bool",
          name: "",
          type: "bool",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "isPublicMintingOpen",
      outputs: [
        {
          internalType: "bool",
          name: "",
          type: "bool",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "isWhitelistMintingOpen",
      outputs: [
        {
          internalType: "bool",
          name: "",
          type: "bool",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "name",
      outputs: [
        {
          internalType: "string",
          name: "",
          type: "string",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "owner",
      outputs: [
        {
          internalType: "address",
          name: "",
          type: "address",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
      ],
      name: "ownerOf",
      outputs: [
        {
          internalType: "address",
          name: "",
          type: "address",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "price",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "",
          type: "address",
        },
      ],
      name: "publicMintedAmount",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "renounceOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "bytes4",
          name: "interfaceId",
          type: "bytes4",
        },
      ],
      name: "supportsInterface",
      outputs: [
        {
          internalType: "bool",
          name: "",
          type: "bool",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "symbol",
      outputs: [
        {
          internalType: "string",
          name: "",
          type: "string",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
      ],
      name: "tokenURI",
      outputs: [
        {
          internalType: "string",
          name: "",
          type: "string",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "totalSupply",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "newOwner",
          type: "address",
        },
      ],
      name: "transferOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "",
          type: "address",
        },
      ],
      name: "tributeWhitelistMinted",
      outputs: [
        {
          internalType: "bool",
          name: "",
          type: "bool",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "",
          type: "address",
        },
      ],
      name: "vipWhitelistMintedAmount",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "_amount",
          type: "uint256",
        },
      ],
      name: "publicMint",
      outputs: [],
      stateMutability: "payable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "bytes32[]",
          name: "_proof",
          type: "bytes32[]",
        },
      ],
      name: "tributeMint",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "_amount",
          type: "uint256",
        },
        {
          internalType: "bytes32[]",
          name: "_proof",
          type: "bytes32[]",
        },
      ],
      name: "vipMint",
      outputs: [],
      stateMutability: "payable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "string",
          name: "_baseUri",
          type: "string",
        },
      ],
      name: "updateBaseURI",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "bytes32",
          name: "_vipWhitelitsMerkleRoot",
          type: "bytes32",
        },
        {
          internalType: "bytes32",
          name: "_tributeWhitelitsMerkleRoot",
          type: "bytes32",
        },
      ],
      name: "updateWhitelist",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "_price",
          type: "uint256",
        },
      ],
      name: "updatePrice",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address[]",
          name: "_addresses",
          type: "address[]",
        },
        {
          internalType: "uint256",
          name: "_amount",
          type: "uint256",
        },
      ],
      name: "airdrop",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "bool",
          name: "_isWhitelistMintingOpen",
          type: "bool",
        },
        {
          internalType: "bool",
          name: "_isPublicMintingOpen",
          type: "bool",
        },
      ],
      name: "updateMintStatus",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "withdraw",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "_royalties",
          type: "uint256",
        },
      ],
      name: "updateRoyalties",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
        {
          internalType: "uint256",
          name: "salePrice",
          type: "uint256",
        },
      ],
      name: "royaltyInfo",
      outputs: [
        {
          internalType: "address",
          name: "receiver",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "royaltyAmount",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "operator",
          type: "address",
        },
        {
          internalType: "bool",
          name: "approved",
          type: "bool",
        },
      ],
      name: "setApprovalForAll",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "operator",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
      ],
      name: "approve",
      outputs: [],
      stateMutability: "payable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "from",
          type: "address",
        },
        {
          internalType: "address",
          name: "to",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
      ],
      name: "transferFrom",
      outputs: [],
      stateMutability: "payable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "from",
          type: "address",
        },
        {
          internalType: "address",
          name: "to",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
      ],
      name: "safeTransferFrom",
      outputs: [],
      stateMutability: "payable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "from",
          type: "address",
        },
        {
          internalType: "address",
          name: "to",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
        {
          internalType: "bytes",
          name: "data",
          type: "bytes",
        },
      ],
      name: "safeTransferFrom",
      outputs: [],
      stateMutability: "payable",
      type: "function",
    },
  ],
  chains: {
    goerli: {
      chainName: "goerli",
      rpcUrls: [
        "https://goerli.infura.io/v3/",
        "https://endpoints.omniatech.io/v1/eth/goerli/public",
      ],
      blockExplorerUrls: ["https://goerli.etherscan.io/"],
      nativeCurrency: {
        name: "ETH",
        symbol: "ETH",
        decimals: 18,
      },
      chain_id: 5,
      chain: "ETH",
    },
    ethereum: {
      chainName: "ethereum",
      rpcUrls: ["https://mainnet.infura.io/v3"],
      blockExplorerUrls: ["https://etherscan.io"],
      nativeCurrency: {
        name: "ETH",
        symbol: "ETH",
        decimals: 18,
      },
      chain_id: 1,
      chain: "ETH",
    },
  },
};
