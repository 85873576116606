import { RootState } from "../services/redux";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface AppState {
  chain: string;
  wallet: string | undefined;
  isLoading: boolean;
}

const initialState: AppState = {
  chain: "",
  wallet: "",
  isLoading: false,
}

export const appSlice = createSlice({
  name: "app",
  initialState: initialState,
  reducers: {
    setChain: (state, action: PayloadAction<string>) => {
      state.chain = action.payload;
    },
    setWallet: (state, action: PayloadAction<string | undefined>) => {
      state.wallet = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    }
  }
});

export const { setChain, setWallet, setIsLoading } = appSlice.actions;

export const selectChain = (state: RootState) => state.app.chain;
export const selectWallet = (state: RootState) => state.app.wallet;
export const selectIsLoading = (state: RootState) => state.app.isLoading;

export default appSlice.reducer;