import { useEffect, useState } from "react";
import hero from "../assets/images/black_transparent.png";
import PublicMint from "../components/PublicMint";
import TributeMint from "../components/TributeMint";
import VipMint from "../components/VipMint";
import { getTotalSupply } from "../services/ethers";
import { useSelector } from "react-redux";
import { selectWallet } from "../store/appSlice";

export default function Mint() {
  const [totalSupply, setTotalSupply] = useState(0);
  const walletConnected = useSelector(selectWallet);

  useEffect(() => {
    (async () => {
      const totalSupply = await getTotalSupply();
      setTotalSupply(totalSupply);
    })();
  }, [walletConnected]);

  return (
    <section id="mint">
      <hgroup>
        <div className="title">
          <h1>TRI</h1>
          <img src={hero} alt="hero tri3ute" />
          <h1>UTE</h1>
        </div>
        <h2 className="text-center">MINT</h2>
        {totalSupply > 0 ? (
          <h3 className="text-center">{totalSupply} / 10000</h3>
        ) : null}
      </hgroup>

      <div className="mt-5 d-flex gap-5 flex-column flex-lg-row w100 justify-content-center align-items-center">
        <PublicMint />
        <TributeMint />
        <VipMint />
      </div>
    </section>
  );
}
