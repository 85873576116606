import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { environment } from "./config";
import { initChain, walletConnect } from "./services/ethers";
import store from "./services/redux";
import { Provider } from "react-redux";
import { Buffer } from "buffer";
import { setIsLoading, setWallet } from "./store/appSlice";

window.Buffer = Buffer;

const { MerkleTree } = require("merkletreejs");
const kekkak256 = require("keccak256");

(async () => {
  const vipLeafNodes = environment.vipWhitelist.map((addr) => kekkak256(addr));
  const vipMerkleTree = new MerkleTree(vipLeafNodes, kekkak256, {
    sortPairs: true,
  });
  const vipRoot = vipMerkleTree.getHexRoot();
  console.log("VIP MERKLE ROOT:", vipRoot);

  const tributeLeafNodes = environment.tributeWhitelist.map((addr) =>
    kekkak256(addr)
  );
  const tributeMerkleTree = new MerkleTree(tributeLeafNodes, kekkak256, {
    sortPairs: true,
  });
  const tributeRoot = tributeMerkleTree.getHexRoot();
  console.log("TRIBUTE MERKLE ROOT:", tributeRoot);
})();

(async () => {
  store.dispatch(setIsLoading(true));
  let wallet;
  initChain();
  try {
    wallet = (await walletConnect()).toLowerCase();
  } catch (error) {
    console.log(String(error));
  }
  store.dispatch(setIsLoading(false));
  store.dispatch(setWallet(wallet));
})();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
