import { useEffect, useState } from "react";
import logo from "../assets/images/black_transparent.png";
import { useNavigate } from "react-router-dom";
import { walletConnect } from "../services/ethers";
import { selectChain, selectWallet } from "../store/appSlice";
import { useSelector } from "react-redux";

function Menu() {
  const navigate = useNavigate();

  return (
    <div className="nav-menu">
      <div className="logo">
        <img src={logo} alt="tri3ute logo" />
      </div>
      <div onClick={() => navigate("mint")} className="menu-item">
        MINT
      </div>
      <div onClick={() => navigate("clothing")} className="menu-item soon">
        CLOTHING
      </div>
      <div onClick={() => navigate("vx-portal")} className="menu-item soon">
        VX PORTAL
      </div>
      <div onClick={() => navigate("locker-room")} className="menu-item soon">
        LOCKER ROOM
      </div>
      <div onClick={() => navigate("ambassadorss")} className="menu-item soon">
        AMBASSADORS
      </div>
    </div>
  );
}

export default function Nav() {
  const [isOpen, setIsOpen] = useState(false);
  const wallet = useSelector(selectWallet);

  useEffect(() => {
    document.addEventListener("click", () => {
      if (isOpen) setIsOpen(false);
    });
  });

  return (
    <div className="nav-container">
      <nav className="d-flex flex-row-reverse w100">
        <div
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen(!isOpen);
          }}
          className={`menu-btn ${isOpen ? "dark" : "light"}`}
        >
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="connect-btn"
          onClick={() => {
            walletConnect();
          }}
        >
          {wallet ? wallet.substring(0, 10) + "..." : "CONNECT WALLET"}
        </div>
        {isOpen ? <Menu /> : null}
      </nav>
    </div>
  );
}
