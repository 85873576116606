import hero from "../assets/images/black_transparent.png";
import banner from "../assets/images/rewards_ab_banner.png";

export default function Home() {
  return (
    <section>
      <div className="d-flex justify-content-center">
        <img
          style={{ width: "80%", maxWidth: "400px" }}
          src={hero}
          alt="hero tri3ute"
        />
      </div>
      <hgroup>
        <h1 className="display-2 text-center">COMING SOON</h1>
      </hgroup>
      <div className="d-flex justify-content-center my-5">
        <img
          style={{ width: "100%", maxWidth: "800px" }}
          src={banner}
          alt="hero tri3ute"
        />
      </div>
    </section>
  );
}
