import publicimg from "../assets/images/public.png";
import publicMintImg from "../assets/images/public-mint.png";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectIsLoading, selectWallet } from "../store/appSlice";
import { isPublicMintingOpen, publicMint, publicMintedAmount } from "../services/ethers";

export default function PublicMint() {
  const walletConnected = useSelector(selectWallet);
    const [mintedAmount, setMintedAmount] = useState(0);

  const price = 0.005; // take from contract (not really needed)
  const maxMints = 4; // take from contract (not really needed)

  const [amount, setAmount] = useState(0);
   const [isMintOpen, setIsMintOpen] = useState(false);
   const isLoading = useSelector(selectIsLoading);

  useEffect(() => {
    if (isLoading) return;
    (async () => {
      const isOpen = await isPublicMintingOpen();
      setIsMintOpen(isOpen);
      if (walletConnected) {
        const mintedAmount = await publicMintedAmount(walletConnected);
        setMintedAmount(mintedAmount);
      }
    })();
  }, [walletConnected]);

  const getPrice = () => {
    return amount * price;
  };
  return (
    <div className="mint-section">
      <img className="hero" src={publicimg} alt="tribute public" />

      <div className="text-top color-dark">
        {/* <p>26TH APRIL '23</p>
        <p>15:00</p> */}
      </div>
      <div className="divider bg-dark"></div>
      <div className="text-bottom color-dark">
        <p>4X PAID MINTS @ 0.005 ETH EACH</p>
      </div>

      <img
        className={`img-btn ${
          maxMints - mintedAmount <= 0 ||
          !isMintOpen ||
          !walletConnected ||
          amount === 0
            ? "disabled"
            : ""
        }`}
        src={publicMintImg}
        alt="vip mint"
        onClick={() => publicMint(amount, getPrice())}
      />
      {!walletConnected ? (
        <p className="mb-0">Connect your wallet to continue</p>
      ) : null}
      {!isMintOpen && !isLoading && walletConnected ? (
        <p className="">The Public Mint is Currently Closed</p>
      ) : null}
      {mintedAmount > 0 ? (
        <p className="">
          You minted {mintedAmount} already. {maxMints - mintedAmount} left.
        </p>
      ) : null}
      <div className="h1 w100 d-flex align-items-center justify-content-around">
        <div
          onClick={() => (amount > 0 ? setAmount(amount - 1) : "")}
          className="p-1 cursor-pointer"
        >
          -
        </div>
        <div>{amount}</div>
        <div
          onClick={() =>
            amount + mintedAmount < maxMints ? setAmount(amount + 1) : ""
          }
          className="p-1 cursor-pointer"
        >
          +
        </div>
      </div>
      <div style={{ marginTop: "-15px" }} className="w100 text-center">
        <i>{getPrice()} ETH</i>
      </div>
    </div>
  );
}
