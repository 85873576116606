import Footer from "./components/Footer";
import Nav from "./components/Nav";
import Router, { ScrollToTop } from "./Router";
import "./styles/styles.scss";

function App() {
  return (
    <>
      <Nav />
      <Router />
      <Footer />
      <ScrollToTop />
    </>
  );
}

export default App;
