import vip from "../assets/images/vip.png";
import vipMintImg from "../assets/images/vip-mint.png";
import { useEffect, useState } from "react";
import { environment } from "../config";
import { useSelector } from "react-redux";
import { selectIsLoading, selectWallet } from "../store/appSlice";
import {
  isWhitelistMintingOpen,
  vipMint,
  vipWhitelistMintedAmount,
} from "../services/ethers";

export default function VipMint() {
  const walletConnected = useSelector(selectWallet);
  const [isMintOpen, setIsMintOpen] = useState(false);
  const isLoading = useSelector(selectIsLoading);
  const [mintedAmount, setMintedAmount] = useState(0);

  const price = 0.005; // take from contract (not really needed)
  const maxMints = 10; // take from contract (not really needed)
  const maxFreeMints = 5; // take from contract (not really needed)

  const [amount, setAmount] = useState(0);

  const getPrice = () => {
    const totalMinting = mintedAmount + amount;

    let totalPaid = 0;
    if (totalMinting > maxFreeMints) totalPaid = totalMinting - maxFreeMints;

    let alreadyPaid = 0;
    if (mintedAmount > maxFreeMints) alreadyPaid = mintedAmount - maxFreeMints;

    return (totalPaid - alreadyPaid) * price;
  };

  useEffect(() => {
    if (isLoading) return;
    (async () => {
      const isOpen = await isWhitelistMintingOpen();
      setIsMintOpen(isOpen);
      if (walletConnected) {
        const mintedAmount = await vipWhitelistMintedAmount(walletConnected);
        setMintedAmount(mintedAmount);
      }
    })();
  }, [walletConnected]);

  if (walletConnected && !environment.vipWhitelist.includes(walletConnected))
    return <></>;

  return (
    <div className="mint-section">
      <img className="hero" src={vip} alt="tribute vip" />

      <div className="text-top color-green">
        {/* <p>26TH APRIL '23</p>
        <p>14:00</p> */}
      </div>
      <div className="divider bg-green"></div>
      <div className="text-bottom color-green">
        <p>5X FREE MINT</p>
        <p>5X PAID MINTS @ 0.005 ETH EACH</p>
      </div>
      <img
        className={`img-btn ${
          maxMints - mintedAmount <= 0 ||
          !isMintOpen ||
          !walletConnected ||
          amount === 0
            ? "disabled"
            : ""
        }`}
        src={vipMintImg}
        alt="vip mint"
        onClick={()=> vipMint(amount, getPrice())}
      />
      {!walletConnected ? (
        <p className="color-green mb-0">Connect your wallet to continue</p>
      ) : null}
      {!isMintOpen && !isLoading && walletConnected ? (
        <p className="color-green">The VIP Mint is Currently Closed</p>
      ) : null}
      {mintedAmount > 0 ? (
        <p className="color-green">
          You minted {mintedAmount} already. {maxMints - mintedAmount} left.
        </p>
      ) : null}
      <div className="color-green h1 w100 d-flex align-items-center justify-content-around">
        <div
          onClick={() => (amount > 0 ? setAmount(amount - 1) : "")}
          className="p-1 cursor-pointer"
        >
          -
        </div>
        <div>{amount}</div>
        <div
          onClick={() =>
            amount + mintedAmount < maxMints ? setAmount(amount + 1) : ""
          }
          className="p-1 cursor-pointer"
        >
          +
        </div>
      </div>
      <div
        style={{ marginTop: "-15px" }}
        className="w100 text-center color-green"
      >
        <i>{getPrice()} ETH</i>
      </div>
    </div>
  );
}
