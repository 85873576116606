import { environment } from "../config";
import { setChain, setWallet } from "../store/appSlice";
import store from "./redux";
// import { environment } from "../config";

const { ethers } = require("ethers");
const { MerkleTree } = require("merkletreejs");
const kekkak256 = require("keccak256");

declare var window: { ethereum: any };

export const ethereum = window.ethereum;

export function initChain() {
  store.dispatch(setChain(String(process.env.REACT_APP_CHAIN)));
}

export async function walletConnect(): Promise<string> {
  if (!window.ethereum) {
    alert("Metamask not installed");
    throw new Error("Metamask not installed");
  }
  const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
  const { chainId } = await provider.getNetwork();
  await checkNetwork(chainId);
  await provider.send("eth_requestAccounts", []);
  const signer = provider.getSigner();
  return signer.getAddress();
}

export async function getWalletConnected() {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    const signer = provider.getSigner();
    return await signer.getAddress();
  } catch (error) {
    return undefined;
  }
}

export async function getAccounts() {
  try {
    const accounts = await window.ethereum.request({ method: "eth_accounts" });
    return accounts;
  } catch (error) {
    console.error("Failed to get accounts", error);
  }
}

async function checkNetwork(chain_id: number): Promise<boolean> {
  const env = { ...environment } as any;
  const chainKey = env.chainId;
  console.log("CHAINKEY", chainKey);

  if (chain_id === env.chains[chainKey].chain_id) return true;

  try {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [
        {
          chainId: "0x" + Number(env.chains[chainKey].chain_id).toString(16),
        },
      ], // chainId must be in hexadecimal numbers
    });
    return true;
  } catch (error: any) {
    if (error.code === 4902) {
      try {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainName: env.chains[chainKey].chainName,
              chainId:
                "0x" + Number(env.chains[chainKey].chain_id).toString(16),
              nativeCurrency: env.chains[chainKey].nativeCurrency,
              rpcUrls: env.chains[chainKey].rpcUrls,
              blockExplorerUrls: env.chains[chainKey].blockExplorerUrls,
            },
          ],
        });
        return true;
      } catch (addError) {
        throw new Error(
          `Error adding ${env.chains[chainKey].chainName} to Metamask`
        );
      }
    }
  }

  throw new Error(
    `Wrong Network (${chain_id}). Switch to ${env.chains[chainKey].chainName} (${env.chains[chainKey].chain_id})`
  );
}

function getContract() {
  let provider;
  let signer;

  if (store.getState().app.wallet) {
    provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    signer = provider.getSigner();
  } else {
    const providersUrl = (process.env.REACT_APP_JSON_RPC as string).split(",");

    // const provider1 = new ethers.providers.JsonRpcProvider(
    //   process.env.REACT_APP_JSON_RPC
    // );

    const providers = providersUrl.map((p) => {
      return { provider: new ethers.providers.JsonRpcProvider(p) };
    });

    provider = new ethers.providers.FallbackProvider(providers, 1);

    const wallet = ethers.Wallet.createRandom();
    signer = wallet.connect(provider);
  }

  return new ethers.Contract(
    String(environment.contract),
    environment.abi,
    signer
  );
}

export async function isWhitelistMintingOpen() {
  const contract = getContract();
  const isOpen = await contract["isWhitelistMintingOpen"]();
  return isOpen;
}

export async function isPublicMintingOpen() {
  const contract = getContract();
  const isOpen = await contract["isPublicMintingOpen"]();
  return isOpen;
}

export async function vipWhitelistMintedAmount(address: string) {
  const contract = getContract();
  const amount = await contract["vipWhitelistMintedAmount"](address);
  return Number(amount);
}

export async function publicMintedAmount(address: string) {
  const contract = getContract();
  const amount = await contract["publicMintedAmount"](address);
  return Number(amount);
}

export async function tributeWhitelistMinted(address: string) {
  const contract = getContract();
  const isMinted = await contract["tributeWhitelistMinted"](address);
  return isMinted;
}

export async function publicMint(amount: number, value: number) {
  const contract = getContract();
  await contract["publicMint"](amount, {
    value: (value * 10 ** 18).toString(),
  });
}

export async function tributeMint() {
  const contract = getContract();
  const tributeLeafNodes = environment.tributeWhitelist.map((addr) =>
    kekkak256(addr)
  );
  const tributeMerkleTree = new MerkleTree(tributeLeafNodes, kekkak256, {
    sortPairs: true,
  });

  const vip1WalletHash = kekkak256(store.getState().app.wallet);
  const proof = tributeMerkleTree.getHexProof(vip1WalletHash);
  await contract["tributeMint"](proof, {
    value: 0,
  });
}

export async function vipMint(amount: number, value: number) {
  const contract = getContract();
  const vipLeafNodes = environment.vipWhitelist.map((addr) => kekkak256(addr));
  const vipMerkleTree = new MerkleTree(vipLeafNodes, kekkak256, {
    sortPairs: true,
  });

  const vip1WalletHash = kekkak256(store.getState().app.wallet);
  const proof = vipMerkleTree.getHexProof(vip1WalletHash);
  await contract["vipMint"](amount, proof, {
    value: (value * 10 ** 18).toString(),
  });
}

export async function getTotalSupply() {
  const contract = getContract();
  const totalSupply = await contract["totalSupply"]();
  console.log("totalSupply", Number(totalSupply));
  return Number(totalSupply);
}
