import tribute from "../assets/images/tribute.png";
import tributeMintImg from "../assets/images/tribute-mint.png";
import { environment } from "../config";
import { useSelector } from "react-redux";
import { selectIsLoading, selectWallet } from "../store/appSlice";
import { useEffect, useState } from "react";
import { isWhitelistMintingOpen, tributeMint, tributeWhitelistMinted } from "../services/ethers";

export default function TributeMint() {
  const walletConnected = useSelector(selectWallet);
  const [isMintOpen, setIsMintOpen] = useState(false);
  const isLoading = useSelector(selectIsLoading);
  const [mintedAmount, setMintedAmount] = useState(0);

  const maxMints = 1; // take from contract (not really needed)

  useEffect(() => {
    if (isLoading) return;
    (async () => {
      const isOpen = await isWhitelistMintingOpen();
      setIsMintOpen(isOpen);
      if (walletConnected) {
        const mintedAmount = await tributeWhitelistMinted(walletConnected);
        setMintedAmount(mintedAmount ? 1 : 0);
      }
    })();
  }, [walletConnected]);

  if (
    walletConnected &&
    !environment.tributeWhitelist.includes(walletConnected)
  )
    return <></>;

  return (
    <div className="mint-section">
      <img className="hero" src={tribute} alt="tribute tribute" />

      <div className="text-top color-white">
        {/* <p>26TH APRIL '23</p>
        <p>14:00</p> */}
      </div>
      <div className="divider bg-white"></div>
      <div className="text-bottom color-white">
        <p>1X FREE MINT</p>
      </div>

      <img
        className={`img-btn ${
          maxMints - mintedAmount <= 0 || !isMintOpen || !walletConnected
            ? "disabled"
            : ""
        }`}
        src={tributeMintImg}
        alt="vip mint"
        onClick={() => tributeMint()}
      />
      {!walletConnected ? (
        <p className="color-white mb-0">Connect your wallet to continue</p>
      ) : null}
      {!isMintOpen && !isLoading && walletConnected ? (
        <p className="color-white mb-0">
          The Tri3ute List Mint is Currently Closed
        </p>
      ) : null}
      {mintedAmount > 0 ? (
        <p className="color-white">
          You minted {mintedAmount} already. {maxMints - mintedAmount} left.
        </p>
      ) : null}
    </div>
  );
}
